import './bootstrap';
import { createSSRApp, h } from 'vue';
import { createInertiaApp, router } from '@inertiajs/vue3';
import { createPinia } from 'pinia';
import vClickOutside from 'click-outside-vue3';
import VueJsTour from '@globalhive/vuejs-tour';
import VueSelect from 'vue-select';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import * as Sentry from '@sentry/vue';
import VueObserveVisibility from 'vue-observe-visibility';
import userHasRoles from './Support/userHasRoles';
import 'vue-select/dist/vue-select.css';
import '../css/app.css';
/* eslint-disable-next-line import/no-unresolved */
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';

createInertiaApp({
  title: (title) => `${title} - Street Insights`,
  // eslint-disable-next-line global-require,import/no-dynamic-require
  resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
  progress: {
    color: '#1233C9',
  },
  setup({
    el,
    App,
    props,
    plugin,
  }) {
    const app = createSSRApp({ render: () => h(App, props) });

    Sentry.init({
      app,
      dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
      environment: import.meta.env.VITE_APP_ENV,
      beforeBreadcrumb: (breadcrumb, hint) => {
        if (breadcrumb.category === 'xhr') {
          const data = {
            // eslint-disable-next-line no-underscore-dangle
            requestBody: hint.xhr.__sentry_xhr_v2__.body,
            response: hint.xhr.response,
            responseUrl: hint.xhr.responseURL,
          };

          return { ...breadcrumb, data };
        }

        return breadcrumb;
      },
    });

    app
      .use(plugin)
      .use(vClickOutside)
      .use(VueJsTour)
      .use(createPinia())
      .use(ZiggyVue, Ziggy)
      .use(VueObserveVisibility)
      .mixin({
        methods: {
          userHasRoles,
        },
      })
      .component('v-select', VueSelect)
      .mount(el);

    return app;
  },
});

router.on('navigate', (event) => {
  window.dataLayer?.push({
    url: event.detail.page.url,
    event: 'pageview',
  });
});
